<template>
    <div class="card card-f-h flex flex-col">
        <ConfirmationModal codeConfirmation @success="getAll" />

        <ModalAddAbsence
            :modalVisible="modalAddAbsenceVisible"
            @close="modalAddAbsenceVisible = false"
            @refresh="getAll"
        />

        <ModalEditAbsence
            :modalVisible="modalEditAbsenceVisible"
            :absenceUuid="selectedAbsenceUuid"
            @close="modalEditAbsenceVisible = false"
            @refresh="getAll"
        />

        <div class="flex mb-5 justify-end">
            <el-button type="primary" :disabled="userCan('create')" @click="showAddModal">
                {{ $t('common.add') }}
            </el-button>
        </div>

        <AbsenceTable
            v-loading="$wait.is('absence.loading')"
            element-loading-spinner="el-custom-spinner"
            :data="absenceData"
            @edit="showEditModal"
            @changeStatus="changeStatus"
            @destroy="destroy"
        />

        <InitialLoader v-if="$wait.is('loading.initial_*')" />

        <NoDataInformation :data="noDataInformation" waitKey="absence.loading" />
    </div>
</template>
<script>
export default {
    components: {
        AbsenceTable:     () => import(/* webpackChunkName: "AbsenceTable" */ './components/AbsenceTable'),
        ModalAddAbsence:  () => import(/* webpackChunkName: "ModalAddAbsence" */ './modals/ModalAddAbsence'),
        ModalEditAbsence: () => import(/* webpackChunkName: "ModalEditAbsence" */ './modals/ModalEditAbsence'),
    },

    data() {
        return {
            noDataInformation:       false,
            absenceData:             [],
            modalAddAbsenceVisible:  false,
            modalEditAbsenceVisible: false,
            selectedAbsenceUuid:     null,
        };
    },

    beforeCreate() {
        this.$wait.start('loading.initial_absence');
    },

    created() {
        this.$store.commit('setPageTitle', `${this.$t('employees.absence')}`);
        this.$store.commit('setActiveMenuItem', '3-6');

        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('absence.loading');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/absence`);
            this.absenceData = data;
            this.noDataInformation = data.length;
            this.$wait.end('absence.loading');
            this.$wait.end('loading.initial_absence');
        },

        showAddModal() {
            this.modalAddAbsenceVisible = true;
        },

        showEditModal(absenceUuid) {
            this.selectedAbsenceUuid = absenceUuid;
            this.modalEditAbsenceVisible = true;
        },

        async changeStatus(item) {
            const response = await this.$confirm(this.$t('common.are_you_sure'), this.$t('common.warning'), {
                confirmButtonText: this.$t('common.yes'),
                cancelButtonText:  this.$t('common.cancel'),
                type:              'warning',
            }).catch(() => {});

            if (response !== 'confirm') return;

            this.$wait.start('absence.loading');
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/employees/absence/${item.uuid}/change_status`, {
                status: item.status,
            });
            this.getAll();
            this.$wait.end('absence.loading');
        },

        async destroy(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  `${process.env.VUE_APP_CORE_API_URL}/employees/absence/${uuid}`,
                actionType: 'destroy',
            });
        },
    },
};
</script>
